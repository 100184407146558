<template>
    <div class="container" >
        <a-spin :spinning="data.loading">
            <a-tabs v-model:activeKey="mainKey" tab-position="top">
                <a-tab-pane key="1" tab="任务">
                    <a-card style="margin: 20px 0">
                        <a-form layout="inline" :model="formState">
                            <a-form-item label="患者名称">
                                <a-input v-model:value="formState.patientName" placeholder="患者名称" allow-clear
                                         @change="search">
                                    <template #prefix>
                                        <UserOutlined style="color: rgba(0, 0, 0, 0.25)"/>
                                    </template>
                                </a-input>
                            </a-form-item>
                            <a-form-item label="任务名称">
                                <a-input v-model:value="formState.taskName" placeholder="任务名称" allow-clear
                                         @change="search">
                                    <template #prefix>
                                        <LockOutlined style="color: rgba(0, 0, 0, 0.25)"/>
                                    </template>
                                </a-input>
                            </a-form-item>
                            <a-form-item>
                                <a-space>
                                    <a-button type="primary" @click="search">
                                        <template #icon>
                                            <SearchOutlined/>
                                        </template>
                                        搜索
                                    </a-button>
                                </a-space>
                            </a-form-item>
                        </a-form>
                    </a-card>
                    <a-table :dataSource="pendingData" :columns="taskColumns" bordered :pagination="pagination"
                             @change="changePageIndex" :scroll="{ y: 400 }" size="small">
                        <template #bodyCell="{ column, record }">
                            <template v-if="column.key === 'relatedName'">
                                <a href="javascript:void(0);" @click="onDdetails(record.relatedId)">{{
                                    record.relatedName
                                    }}</a>
                            </template>
                            <template v-if="column.key === 'action'">
                                <a-space>
                                    <a-button v-if="record.type === 'WEB'" type="link" @click="onAction(record)">执行
                                    </a-button>
                                    <a-button v-if="record.type === 'CS'" type="link" @click="callCsClient(record)">{{
                                        record.comment
                                        }}
                                    </a-button>
                                    <a-button v-if="record.type === 'CS'" type="link" @click="onAction(record)">完成
                                    </a-button>
                                    <span v-if="record.type === 'MOBILE'">移动端</span>
                                    <a-button v-if="record.cloudAssign" type="link" @click="openModal(record)">分配
                                    </a-button>
                                </a-space>
                            </template>
                        </template>
                    </a-table>
                </a-tab-pane>

                <a-tab-pane key="2" tab="流程">
                    <a-table :dataSource="pendingData" :columns="processColumns" bordered :pagination="pagination"
                             @change="changePageIndex" :scroll="{ y: 400 }" size="small">
                        <template #bodyCell="{ column, record }">
                            <template v-if="column.key === 'relatedName'">
                                <a href="javascript:void(0);" @click="onDdetails(record.relatedId)">{{
                                    record.relatedName
                                    }}</a>
                            </template>
                            <template v-if="column.key === 'operation'">
                                <a-button type="link" @click="cancelOrder(record)">结束流程</a-button>
                            </template>
                        </template>
                    </a-table>
                </a-tab-pane>
            </a-tabs>
        </a-spin>
        <div>
            <a-modal v-model:visible="assignState.visible" title="分配任务"
                     :confirm-loading="assignState.confirmLoading"
                     @ok="handleOk">
                <a-card :loading="assignState.pageLoading">
                    <a-radio-group v-model:value="assignState.selectedAssignee" :options="assignState.subordinateUsers">
                    </a-radio-group>
                </a-card>
            </a-modal>
        </div>
    </div>
</template>
<script setup>
import {onMounted, reactive, ref, toRefs, watch, createVNode} from "vue";
import {getCurrentProcess, getTaskCurrent, reAssign} from "@/api/home.ts";
import message from "ant-design-vue/lib/message";
import {useRouter} from "vue-router";
import {finishTask} from "@/api/home";
import {getDentalCAD} from "@/api/dentalCAD";
import {formatDateTime} from "@/components/ts/components";
import {deleteInstance} from "@/api/instance";
import {getMySubordinateUsers} from "@/api/sys_user";
import {Modal} from 'ant-design-vue';
import {ExclamationCircleOutlined} from '@ant-design/icons-vue';
import {LocalStorageService} from "@/components/ts/localStorage";
import {PERMISSION} from '@/store/mutation-types'

const mainKey = ref("1");

const processColumns = [
    {
        title: "序号",
        dataIndex: "key",
        key: "key",
        customRender: ({index}) => `${index + 1}`,
        width: 80,
    },
    {
        title: "患者姓名",
        dataIndex: "relatedName",
        key: "relatedName",
    },
    {
        title: "流程",
        children: [
            {
                title: "编号",
                dataIndex: "processCode",
                key: "processCode",
            },
            {
                title: "名称",
                dataIndex: "processName",
                key: "processName",
            },
            {
                title: "发起",
                dataIndex: "initiatorName",
                key: "initiatorName",
            },
            {
                title: "时间",
                dataIndex: "startTime",
                key: "startTime",
                width:180,
                customRender: ({record}) => {
                    return record.startTime ? formatDateTime(record.startTime) : "";
                },
            },
        ]
    },
    {
        title: "执行",
        children: [
            {
                title: "耗时",
                dataIndex: "executeTime",
                key: "executeTime",
            },
        ]
    },
    {
        title: "当前任务",
        children: [
            {
                title: "编号",
                dataIndex: "currTaskCode",
                key: "currTaskCode",
            },
            {
                title: "名称",
                dataIndex: "currTaskName",
                key: "currTaskName",
            },
            {
                title: "执行人",
                dataIndex: "currTaskAssigneeName",
                key: "currTaskAssigneeName",
            },
        ]
    },
    {
        title: "操作",
        dataIndex: "operation",
        key: "operation",

    },

];
const taskColumns = [
    {
        title: "序号",
        dataIndex: "key",
        key: "key",
        customRender: ({index}) => `${index + 1}`,
        fixed: 'left',
    },
    {
        title: "患者姓名",
        dataIndex: "relatedName",
        key: "relatedName",
        fixed: 'left',
    },
    {
        title: "流程",
        children: [
            {
                title: "编号",
                dataIndex: "processDefinitionKey",
                key: "processDefinitionKey",
            },
            {
                title: "名称",
                dataIndex: "processDefinitionName",
                key: "processDefinitionName",
            },
            {
                title: "发起",
                dataIndex: "instanceStartUserName",
                key: "instanceStartUserName",
            },
            {
                title: "时间",
                dataIndex: "createTime",
                key: "createTime",
                width:160,
                customRender: ({record}) => {
                    return record.createTime ? formatDateTime(record.createTime) : "";
                },
            }
        ]
    },
    {
        title: "任务",
        children: [
            {
                title: "编号",
                dataIndex: "code",
                key: "code",
            },
            {
                title: "名称",
                dataIndex: "name",
                key: "name",
            },
        ]
    },
    {
        title: "耗时(小时)",
        dataIndex: "executeTime",
        key: "executeTime",
    },
    {
        title: "审核意见",
        dataIndex: "opinion",
        key: "opinion",
        customRender: ({record}) => `${record.variables && record.variables.opinion ? record.variables.opinion : ''}`,
    },
    {
        title: "操作",
        key: "action",
        width: 200,
        fixed: 'right',
    },
];
const data = reactive({
    assignState: {
        visible: false,
        confirmLoading: false,
        selectedAssignee: "",
        taskId: "",
        subordinateUsers: [],
        pageLoading: false,
    },
    loading: false,
    pendingData: [],
    pagination: {
        size: "small",
        current: 1,
        pageSize: 10,
        total: 0,
        pageSizeOptions: ["10", "20", "30"],
        showTotal: (total) => {
            return " 共" + total + "条";
        },
        hideOnSinglePage: false,
        showSizeChanger: true,
    },
});
const {pendingData, pagination, assignState} = toRefs(data);


const formState = reactive({
    patientName: "",
    taskName: "",
})

watch(mainKey, (newName) => {
    console.log("newName", newName);
    pendingData.value = [];
    data.pagination.pageSize = 10;
    data.pagination.current = 1;
    switch (newName) {
        case "1":
            taskCurrent();
            break;
        case "2":
            currentProcess();
            break;

        default:
            break;
    }
});
const handleOk = async () => {
    if (assignState.value.selectedAssignee && assignState.value.taskId) {
        console.log("更换执行人", assignState.value.selectedAssignee, assignState.value.taskId);
        assignState.value.confirmLoading = true;
        await reAssign({
            nextTaskAssignee: assignState.value.selectedAssignee,
            runTimeTaskId: assignState.value.taskId
        }).then(() => {
            message.success("操作成功");
            taskCurrent();
            assignState.value.confirmLoading = false;
            assignState.value.visible = false;
        }).catch(() => {
            assignState.value.confirmLoading = false;
            assignState.value.visible = false;
        })
    }
}

const openModal = async (record) => {
    assignState.value.taskId = record.runTimeTaskId;
    assignState.value.visible = true;
    assignState.value.pageLoading = true;
    await getMySubordinateUsers().then(res => {
        if (res) {
            assignState.value.subordinateUsers = res.map(e => {
                return {
                    value: e.id,
                    label: e.name
                }
            });
        }
        assignState.value.pageLoading = false
    }).catch(() => {
        assignState.value.pageLoading = false
    })
}
// const radioStyle = reactive({
//     display: 'flex',
//     height: '30px',
//     lineHeight: '30px',
// });
onMounted(() => {
    taskCurrent();
});

const currentProcess = () => {
    let option = {
        page: {
            currPage: pagination.value.current,
            pageSize: pagination.value.pageSize,
        },
    };
    data.loading = true;
    getCurrentProcess(option).then((res) => {
        console.log(res);
        pendingData.value = [];
        if (res) {
            pendingData.value = res.data;
            pagination.value.total = res.records;
        }
        data.loading = false;
    }).catch(()=>{
        data.loading = false;
    });
};

const callCsClient = (info) => {
    console.log("call cs client.", info);
    let params = {
        "nextTaskAssignee": "",
        "params": {
            diagnosisOrderId: info.variables.diagnosisOrderId
        },
        "processDefinitionId": info.processDefinitionId,
        "processDefinitionKey": info.processDefinitionKey,
        "processInstanceId": info.instanceId,
        "runTimeTaskId": info.runTimeTaskId,
        "taskCode": info.code
    }
    if (info["variables"]) {
        let variables = info.variables;
        for (let key in variables) {
            params.params[key] = variables[key];
        }
    }
    getDentalCAD(params).then(res => {
        window.location.href = res + '&url=' + location.origin + '/admin';
    })
};

const search = () => {
    data.pagination.current = 1;
    taskCurrent()
}
const taskCurrent = () => {
    let option = {
        code: "",
        instanceId: "",
        keyword: "",
        name: formState.taskName,
        patientName: formState.patientName,
        page: {
            currPage: pagination.value.current,
            pageSize: pagination.value.pageSize,
        },
    };
    data.loading = true;
    getTaskCurrent(option).then((res) => {
        console.log(res);
        pendingData.value = [];
        if (res) {
            pendingData.value = res.data;
            pagination.value.total = res.records;
        }
        data.loading = false;
    }).catch(() => {
        data.loading = false;
    });
};
const changePageIndex = (page) => {
    data.pagination.pageSize = page.pageSize;
    data.pagination.current = page.current;
    mainKey.value == "2" ? currentProcess() : taskCurrent();
};

const router = useRouter();
const onAction = (info) => {

    let parameters = {
        processDefinitionId: info.processDefinitionId,
        processDefinitionKey: info.processDefinitionKey,
        processInstanceId: info.instanceId,
        runTimeTaskId: info.runTimeTaskId,
        taskCode: info.code,
    };

    const queries = getUrlParameters(info.url)
    if (queries) {
        console.log(queries)
        for (const key in queries) {
            parameters[key] = queries[key]
        }
    }

    console.log(parameters)

    if (info["variables"]) {
        let variables = info.variables;
        for (let key in variables) {
            parameters[key] = variables[key];
        }
    }
    if (info.type != 'CS') {
        if (info.url === '/task36') {
            parameters.productInfo = null;
            parameters.prescription = null;
        }
        router.push({
            path: info.url,
            query: parameters,
        });
    } else {
        Modal.confirm({
            title: '是否确认完成该任务?',
            icon: createVNode(ExclamationCircleOutlined),
            content: '',
            okText: '确认',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
                let option = {
                    processDefinitionId: info.processDefinitionId,
                    processDefinitionKey: info.processDefinitionKey,
                    processInstanceId: info.instanceId,
                    runTimeTaskId: info.runTimeTaskId,
                    taskCode: info.code,
                };

                let parameters = {};
                if (info["variables"]) {
                    let variables = info.variables;
                    for (let key in variables) {
                        parameters[key] = variables[key];
                    }
                    option["params"] = parameters;
                }
                finishTask(option).then(() => {
                    message.success("操作成功");
                    taskCurrent();
                });
            },
            onCancel() {
                console.log('Cancel');
            },
        });

    }
};


const getUrlParameters = (url) => {
    let tmp = url.split("?")
    if (tmp.length !== 2) return null
    const parameters = tmp[1].split("&")
    const item = {}
    for (let index in parameters) {
        const parameter = parameters[index].split("=")
        item[parameter[0]] = parameter[1]
    }
    return item
}
const cancelOrder = (record) => {
    Modal.confirm({
        title: '确认结束该流程?',
        icon: createVNode(ExclamationCircleOutlined),
        content: '结束后之前操作的任务都将作废!!!',
        onOk() {
            deleteInstance({instanceId: record.id}).then(() => {
                currentProcess();
            })
        },
        onCancel() {
            console.log('Cancel');
        },
        class: 'test',
    });
}
const onDdetails = (id) => {
    const permission = LocalStorageService.get(PERMISSION)
    if (permission.includes('patient')) {
        router.push({
            path: '/patient/patientInfo',
            query: {
                id
            }
        })
    }
}
</script>

<style scoped>
a {
    color: black;
}

#components-badge-demo-dot .anticon-notification {
    width: 16px;
    height: 16px;
    line-height: 16px;
    font-size: 16px;
}
</style>